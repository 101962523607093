.mx-icon-Vector-2 {
  font-size: 17px;
}
.mx-icon-Vector-4 {
  font-size: 17px;
}
.mx-icon-call-back-2 {
  font-size: 16px;
}
.mx-icon-Group-16230 {
  font-size: 16px;
}
.mx-icon-arrow_drop_down_FILL0_wght400_GRAD0_opsz48-2 {
  font-size: 20px;
}
.mx-icon-drop-down {
  font-size: 26px;
}
.mx-icon-view-less {
  font-size: 7px;
}
.mx-icon-download::before {
  color: black;
}
