// .file_text_wraper {
//     position: absolute;
//     top: 6px;
//     font-size: 6px;
//     left: 7px;
//     font-weight: 900;
//   }
//   .file_wraper_icon {
//     position: relative;
//     color: #ffffff;
//   }
//   .file_text_wraper--field {
//     position: absolute;
//     top: 8px;
//     font-size: 8px;
//     left: 7px;
//     font-weight: 900;
//   }







 
  

  
  .cp-timeline-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  

  



  
  
  
  .cp-expand__lable {
    width: 10%;
    text-align: right;
  }
  
  .cp-discretion-expand-content {
    margin-top: 15px;
  }
  
  .cp-discretion-expand__view-field {
    display: flex;
    padding: 5px;
    border: 0.5px solid rgb(205, 206, 222);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .cp-expand__cont {
    padding-left: 15px;
  }
  
  .cp-discretion-expand__invoice-view-field {
    display: flex;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .cp-expand__invoice-lable {
    width: 25%;
    text-align: right;
  }
  
  .cp-invoice-alert {
    background-color: #fd372a;
    padding: 4px;
    border-radius: 25px;
    height: 6px;
    margin-right: 5px;
  }
  
  .cp-invoice-due {
    display: flex;
    align-items: center;
  }
  
  .cp-action-switch-content {
    padding: 40px 40px;
  }
  
  .cp-action-title--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .cp-action-instruction p {
    width: 100%;
  }
  
  .cp-action-instruction {
    // background-color: #fafafa;
    // padding: 15px;
    border-radius: 5px;
  }
  
  .cp-action-reciever-content {
    display: flex;
    margin-top: 25px;
    // margin-bottom: 25px;
  }
  
  .cp-action-select {
    width: 100%;
    padding-right: 20px;
  }
  
  .cp-action-switch-content {
    overflow: scroll;
    height: 30%;
  }
  
  .cp-action-outcome-content {
    display: flex;
    // justify-content: space-between;
    gap: 5px;
  }
  
  // .cp-action-outcome-content svg {
  //   width: 30px;
  //   height: 30px;
  // }
  
  .cp-action-outcome {
    margin-bottom: 25px;
  }
  
  .cp-action-promise-amount_input {
    display: flex;
    align-items: center;
    gap: 70px;
  }
  
  .cp-action-pay_action {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  // .action-promise-amount--input {
  //   margin-top: 10px;
  // }
  .cp-action-message-confirmation {
    // padding-top: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    height: 55px;
    // margin-bottom: 15px;
  }
  
  .cp-message-confirmation-checkbox {
    width: 25%;
  }
  
  .cp-message-confirmation-type {
    width: 90px;
    margin-top: 15px;
  }
  
  .cp-action-promise-pay-con {
    margin-top: 15px;
    width: 260px;
  }
  
  .cp-quill {
    height: 160px;
  }
  
  .cp-quill--action-view {
    height: 300px;
  }
  
  .--cp-cnt-quill .ql-editor {
    padding: 0px 15px 12px 15px;
  }
  
  .cp-quill .quill {
    height: 100% !important;
  }
  
  .cp-quill .quill .ql-container {
    overflow: auto;
  }
  
  .cp-quill-subject .ql-container {
    border-radius: 5px;
  }
  
  .cp-action-switch-container {
    height: 100%;
    overflow: scroll;
    padding: 20px 30px;
  }
  
  .cp-action-switch-container-review {
    height: 100%;
    overflow: scroll;
    // padding: 20px 30px;
  }
  
  .cp-action-switch-footer {
    // display: flex;
    // justify-content: flex-end;
    background-color: #f9f9f9;
    // height: 73px;
    align-items: center;
    padding: 10px;
  }
  
  .cp-action-btn {
    min-width: 126px;
  }
  
  // .cp-action-switch-container form {
  //   height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  // }
  .cp-action-promise_test-area {
    margin-top: 10px;
  }
  
  .cal--select {
    width: 135px;
  }
  
  .cp-action-callback {
    width: 135px;
    margin-top: 15px;
  }
  
  .cp-action-dispute__service {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cp-action-dispute__select-servise {
    width: 605px;
  }
  
  .cp-dispute__select-status {
    width: 270px;
  }
  
  .modifi--select-status {
    display: flex;
    align-items: center;
  }
  
  .cp-card__tag-align {
    justify-content: space-between;
    align-items: center;
    //  margin-top: 30px;
  }
  
  .cp-total-tag {
    background-color: #f5f6fa;
    height: 22px;
    width: 22px;
    text-align: center;
    border-radius: 5px;
  }
  
  .cp-card__tag-holder {
    display: flex;
    align-items: center;
  }
  
  .cp-tag-card-border {
    padding-bottom: 0px;
    // border-bottom: 0.5px solid #cdced9;
    margin-bottom: 5px;
    padding-right: 10px;
  }
  
  .cp-card__contact-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .contact-btn {
    display: none;
  }
  
  .contact-name {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }
  
  .contact-user {
    padding-top: 15px;
    padding-right: 10px;
    display: block;
  }
  
  .cp-card-conatiner:hover .contact-user {
    display: none;
  }
  
  .cp-card-conatiner:hover .contact-name {
    max-width: 35px;
    min-width: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .cp-card-conatiner:hover .contact-btn {
    display: flex;
    position: relative;
    right: 0;
    top: 4px;
  }
  
  .cp-contact__d-flex p {
    min-width: 110px;
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  
  .cp-contact__d-flex svg path {
    fill: #4b4f72;
    width: 15px;
    height: 15px;
  }
  
  .cp-contact__d-flex {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
  }
  
  .cp-card-hiper-link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .cp-card-hiper-link a {
    color: #516beb;
    font-size: 12px;
    font-weight: 400;
  }
  
  .cp-card-conatiner {
    padding: 0px 0px 5px 10px;
    border: 0.5px solid #cdced9;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: 1px 2px 4px #33386025;
    width: 200px;
  }
  
  .cp-card-hiper-link svg path {
    fill: #ffffff;
    stroke: width 0;
  }
  
  .cp-card-hiper-link svg path {
    fill: #ffffff;
    stroke-width: 0;
  }
  
  .cp-card-conatiner:hover .cp-card-hiper-link svg path {
    fill: #adadad;
    stroke: #adadad;
  }
  
  .cp-unsuccessful-msg-sec {
    margin-bottom: 15px;
  }
  
  .cp-action-call-outcome {
    border: 1px solid #cdced9;
    height: 36px;
    width: 100px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .cp-action-call-outcome div {
    color: #333860;
  }
  
  .cp-action-call-outcome svg path {
    fill: #333860;
  }
  
  .cp-action-call-outcome--active {
    border: 2px solid #516beb;
    height: 36px;
    width: 100px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .cp-action-call-unsuccessful {
    border: 1px solid #cdced9;
    height: 36px;
    width: 122px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .cp-action-call-unsuccessful--active {
    border: 2px solid #516beb;
    height: 36px;
    width: 122px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .circ-clr-wts {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #dbeadc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .disable-circ-clr-wts {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .disable-circ-clr-wts svg path {
    fill: #adadad;
  }
  
  .cp-action-call-unsuccessful--active {
    color: #516beb;
    font-weight: bold;
  }
  
  .cp-action-call-outcome--active div {
    color: #516beb;
  }
  
  .cp-action_unsuccess--setdate {
    height: 46px;
    width: 125px;
    border: 0.5px solid #cdced9;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    user-select: none;
    cursor: pointer;
  }
  
  .cp-action-call-outcome--active-red {
    border: 2px solid #fd372a;
    height: 36px;
    width: 100px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .cp-action-call-outcome--active-red div {
    color: #fd372a;
  }
  
  .cp-action-call-outcome--active-red svg path {
    fill: #fd372a;
  }
  
  .onHov:hover {
    background-color: #f5f7ff;
    border-radius: 5px;
  }
  
  .onHov :hover svg path {
    fill: #ffffff !important;
  }
  
  .onHov :hover .circ-clr-wts {
    background-color: #4caf50 !important;
  }
  
  .onHov :hover .circ-clr {
    background-color: #516beb !important;
  }
  
  .onHov:hover .clr-text {
    color: #516beb !important;
  }
  
  .c {
    color: #adadad !important;
    user-select: none;
  }
  
  .enabled {
    color: #333860 !important;
  }
  
  .svgQuickActionDisable {
    background: #e3e3e3;
  }
  
  .svgQuickActionEnable {
    background: #eaeeff;
  }
  
  .svgQuickActionWhatsAppEnable {
    background: #dbeadc;
  }
  
  .svgQuickActionDisableBox {
    background: #e3e3e3;
    box-shadow: "";
  }
  
  .svgQuickActionEnableBox {
    background: #ffffff;
    border: 0.5px solid #cdced9;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  }
  
  .disable {
    color: #adadad !important;
  }
  
  .quick-action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
  }
  
  .quick-action-pointer {
    pointer-events: none;
  }
  
  // .cp-card__total-mail{
  //   background-color: #516beb;
  //   padding: 3px;
  //   border-radius: 5px;
  //   margin-left: 12px;
  //   color: #fff;
  // }
  .enabled-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #eaeeff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .disable-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #e3e3e3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .disable-circle svg path {
    fill: #adadad !important;
  }
  
  .cp-card__total-mail {
    background-color: #ffffff;
    text-align: center;
    border-radius: 5px;
    margin-left: 12px;
    // padding: 0px 6px 0px 6px;
    width: 24px;
    height: 14px;
    border: 1px solid #516beb;
  }
  
  .cp-card__total-mail div {
    font-size: 13px;
  }
  
  .cp-payment-content {
    display: flex;
    justify-content: end;
    padding-right: 30px;
    margin-bottom: 20px;
  }
  
  .cp-add-payment__btn {
    margin-right: 15px;
    width: 160px;
  }
  
  .cp-payment__icon {
    margin-right: 5px;
    margin-top: 0px;
  }
  
  .cp-add-payment__btn svg path {
    fill: #516beb;
  }
  
  .cp-add-payment__btn svg {
    margin-right: 10px;
  }
  
  .cp-payment-tab__table {
    display: block;
    position: relative;
    overflow-x: scroll;
    overflow-y: scroll;
    /* height: 458px; */
    height: 100%;
    text-align: center;
  }
  
  .cp-payment__filter {
    display: flex;
    justify-content: end;
  }
  
  .cp-card-hiper-link a {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .cp-card-hiper-link svg path {
    fill: #ffffff;
  }
  
  .dairy-ovr .cp-card-hiper-link svg path {
    fill: #adadad;
  }
  
  .word-space {
    display: -webkit-box;
    // word-break: break-all;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .word-popup {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 450px;
  }
  
  // .on-expand {
  //   // word-break: break-all;
  // }
  
  .access_nth_child:nth-child(1) .table-main__head-cell {
    width: 30px !important;
  }
  
  .cp-contact-details__add-btn button:hover svg path {
    fill: #ffffff;
  }
  
  .cp-contact-details__add-btn button svg path {
    fill: #333860;
  }
  
  .cp-discretion__icon-alert svg path {
    fill: #adadad;
  }
  
  .cp-expand__form-input {
    margin-top: 10px;
  }
  
  .cp-expand__type {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  
  .cp-expand__confir-check {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .cp-expand__confirm-input {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    margin-left: 30px;
  }
  
  .cp-expand__type-input {
    width: 155px;
  }
  
  .cp-expand__promise-confirm {
    width: 300px;
  }
  
  .cp-expand__rec-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .--width-modified {
    width: 320px;
  }
  
  .cp-card__default-click h6 {
    color: #ffffff;
    cursor: pointer;
  }
  
  .cp-card-conatiner:hover h6 {
    color: #516beb;
  }
  
  .cp-tab__table-haeder-sort div:nth-child(1) {
    width: 100%;
  }
  
  .setting-active {
    border-bottom: 0.5px solid #f0f5ff;
    // border-radius: 5px;
    // margin-bottom: 20px;
    // height: 100px;
    padding: 15px;
    cursor: pointer;
    box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px 0px #0000000f;
    background-color: #f0f5ff;
  }
  
  .setting-active .is-configured {
    // width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: bold;
    background-color: #ffffff;
    color: #0fb158;
  }
  
  .setting-active .label {
    color: #333860;
  }
  
  .setting-active .des {
    color: #ffffff;
  }
  
  .setting-inactive {
    border-bottom: 0.5px solid #cdced9;
    // border-radius: 5px;
    // margin-bottom: 20px;
    // height: 100px;
    padding: 15px;
    cursor: pointer;
    // box-shadow: 0px 1px 3px 0px #0000001a, 0px 1px 2px 0px #0000000f;
  }
  
  .setting-inactive .des {
    color: #adadad;
  }
  
  .setting-inactive:hover {
    // border: 0.5px solid #516beb;
    background-color: #f0efef;
  }
  
  .setting-inactive .label {
    color: #333860;
  }
  
  .setting-inactive .is-configured {
    // border: 0.5px solid #0fb158;
    // color: #0fb158;
    // width: 100px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: bold;
    background-color: #ffffff;
  }
  
  .nested-view-content-icon-payment:hover svg path {
    fill: #fd372a !important;
  }
  
  // .cp-tab__table-body-content:hover svg path {
  //   fill: #adadad;
  // }
  
  .cp-tab__table-body-content svg path {
    fill: #333860;
  }
  
  // .cp-tab__table-body-content svg path{
  //   fill:#ffffff
  // }
  // .cp-tab__table-body-content:hover .cp-tab__table-body-cell:nth-child(9) {
  //   width: 30px !important;
  // }
  
  .cp-tab__table-body-content:hover .payment-value {
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: inline-block;
    margin-top: 3px;
  }
  
  .cp-tab__table-body-content .payment-value {
    white-space: nowrap;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: inline-block;
    margin-top: 3px;
  }
  
  .action-button {
    display: none;
    // position: absolute;
  }
  
  // .cp-tab__table-body-content
  .cp-tab__table-body-content:hover .action-button {
    display: flex;
    // align-items: center;
    // justify-content: flex-end;
    position: absolute;
    right: 0%;
  }
  
  // .cp-tab__table-body-content .cp-tab__table-body-cell:nth-child(7) p{
  //   white-space: nowrap;
  //   width: 50px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   position: relative;
  //   display: inline-block;
  //   margin-top: 3px;
  // }
  .workflow-name {
    white-space: nowrap;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .svg-primary-fill path {
    fill: $primary !important;
  }
  
  .cp-tab__table-header-cell-content {
    // width: 100px !important;
  }
  
  .payment-popup_btn {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .payment-popup_btn svg path {
    fill: #333860 !important;
  }
  
  .payment-popup_btn:hover {
    background-color: #f0f3f8;
  }
  
  .content-header__back {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .content-header__back svg {
    height: 28px;
    width: 28px;
  }
  
  .content-header__back svg path {
    fill: #333860;
  }
  
  .content-header__back a {
    padding-top: 4px;
  }
  
  .--next_action {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  }
  
  .cp__table__overview__wraper {
    display: flex;
    width: calc(100% - 60px);
  }
  
  .cp__customerm__tabel-container {
    display: flex;
    background-color: #ffffff;
    width: 250px;
    flex-direction: column;
    height: 100vh;
    width: 250px;
  }
  
  .cp__customer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex: 1;
    height: 70px;
    border-bottom: 0.5px solid #c7c7c7;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .cp_currency__style {
    font-size: 10px;
    font-weight: 400;
    // margin-top: 2px;
    color: #adadad;
  }
  
  .cp__back_btn_wraper {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    padding-left: 10px;
  }
  
  .cp__back_btn_wraper div {
    margin-top: 3px;
  }
  
  .cp__customer__list-wraper {
    height: 100%;
    overflow: scroll;
    width: 100%;
  }
  
  .cp__customer__container:hover {
    background-color: #f0f5ff;
    cursor: pointer;
  }
  
  .custmer__active {
    background-color: #f0f5ff;
  }
  
  // global
  .cp__globalsearch {
    // flex: 1;
    // height: 11%;
    border-bottom: 0.5px solid #c7c7c7;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 99px;
  }
  
  .cp__globalsearch:hover {
    background-color: #f0f5ff;
    cursor: pointer;
  }
  
  .--cp-change__direction {
    flex-direction: column;
  }
  
  .customer-overview_padding {
    padding: 0px 10px;
  }
  
  .cp-total__amount__container {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .cp-total__amt-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  
  .cp-card__action_wraper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    border-bottom: 0.5px solid #c7c7c7;
    padding-bottom: 15px;
  }
  
  .cp-card__action__amount {
    display: flex;
    gap: 10px;
  }
  
  .cp-card__customer_action {
    display: flex;
    gap: 20px;
  }
  
  .cp-allaction__wraper {
    gap: 5px;
    display: flex;
    flex-direction: column;
  }
  
  .cp__action__wraper_valied {
    display: flex;
    border-bottom: 0.5px solid #c7c7c7;
    padding-bottom: 10px;
  }
  
  .--cp_overview__head {
    padding-bottom: 5px;
  }
  
  .cp__chart__wraper {
    display: flex;
    gap: 10px;
    flex-direction: column;
    background-color: #f0f5ff;
    border: 0.5px solid #cdced9;
    border-radius: 5px;
    box-shadow: 1px 2px 4px 0px #33386025;
    padding: 10px 10px;
    // justify-content: space-between;
    min-width: 175px;
    // width: 480px;
  }
  
  .cp__details__amt {
    display: flex;
    // gap: 10px;
  }
  
  .cp__top__section__wraper {
    display: flex;
    gap: 10px;
  }
  
  .cp_right__card__wraper {
    display: flex;
    // gap: 10px;
    flex-direction: column;
    background-color: #ffffff;
    border: 0.5px solid #cdced9;
    border-radius: 5px;
    box-shadow: 1px 2px 4px 0px #33386025;
    padding-top: 10px;
    flex: 1;
    min-width: 175px;
    position: relative;
  }
  
  .right__card__top_conatiner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #c7c7c750;
  }
  
  .right__card__bottom_conatiner {
    display: flex;
    padding-top: 5px;
    justify-content: space-between;
    // align-items: center;
  }
  
  .contact__details__wraper {
    flex: 1;
  }
  
  .cp__tab__container {
    background-color: #ffffff;
    flex: 5;
    border: 0.5px solid #cdced9;
    box-shadow: 1px 2px 4px 0px #33386025;
    border-radius: 5px;
    overflow: hidden;
    min-width: 600px;
  }
  
  .cp__contact__container_right {
    display: flex;
    background-color: #ffffff;
    padding: 18px 7px;
    border-radius: 5px;
    border: 0.5px solid #cdced9;
    box-shadow: 1px 2px 4px 0px #33386025;
    flex: 1;
    overflow: auto;
  }
  
  .cp__resume__btn {
    text-align: end;
    flex: 1 1 0%;
    display: flex;
    justify-content: center;
    // border-right: 1px solid #c7c7c7;
    height: 50px;
    align-items: center;
  }
  
  .--modifier__border {
    border-right: 0px;
    align-items: center;
  }
  
  .--layout_modifier .cp__customerm__tabel-container {
    height: 95vh;
  }
  
  .cp_alert__color svg path {
    fill: #fbbd04 !important;
  }
  
  .cp__resume__btn svg path {
    fill: #516beb;
  }
  
  .--adhoc__table__h {
    overflow: scroll;
  }
  
  .--cp__customer__overview {
    width: calc(100% - 250px);
  }
  
  .cp_customer__action__pause {
    position: absolute;
    text-align: center;
    background-color: #fd372a;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 191px;
    color: #ffffff;
    left: 509px;
    bottom: -6px;
    border: 0.5px solid #cdced9;
  }
  
  .--modifi__card_r {
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .--cp__invoice__body {
    height: calc(100vh - 465px) !important;
    overflow: scroll;
  }
  
  .contact__card_scroll {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    max-height: 270px;
  }
  
  .cp__invoice__table--modifear .table-main__head-cell {
    // flex: 100 0 auto !important;
    width: 80px !important;
  }
  
  .--cus__currencyb {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .--next_action svg {
    width: 18px;
    height: 18px;
  }
  
  .cp-left__customer__list {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .cp-left__customer__list svg {
    width: 18px;
    height: 18px;
  }
  
  .cp-left__customer__list svg path {
    fill: #516beb;
  }
  
  .cp-discretion__text-content {
    width: 100%;
  }
  
  .--inv__modifier {
    height: 80px;
  }
  
  .add_diary_alert {
    padding-right: 8px;
    padding-top: 5px;
  }
  
  .add_diary_alert svg {
    width: 15px;
    height: 15px;
  }
  
  .add_note_alert_btn {
    border: 1px solid #cdced9;
    height: 28px;
    width: 160px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    padding: 0px 10px;
    cursor: pointer;
  }
  
  .add_note_alert_btn h6 {
    color: #adadad;
  }
  
  .add_note_alert_btn svg path {
    fill: #adadad;
  }
  
  .add_note_alert_btn--active {
    border: 2px solid #516beb;
    height: 28px;
    width: 160px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    padding: 0px 10px;
    // justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .add_note_alert_btn--active h6 {
    color: #516beb;
  }
  
  .cp-action-call-outcome--yellow {
    border: 2px solid #fbbd04;
    height: 28px;
    width: 160px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0px 10px;
  }
  
  .cp-action-call-outcome--yellow svg path {
    fill: #fbbd04;
  }
  
  .cp-action-call-outcome--yellow h6 {
    color: #fbbd04;
  }
  
  .cp-action-call-outcome--red {
    border: 2px solid #fd372a;
    height: 28px;
    width: 160px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0px 10px;
  }
  
  .cp-action-call-outcome--red h6 {
    color: #fd372a;
  }
  
  .cp-action-call-outcome--red svg path {
    fill: #fd372a;
  }
  
  .notes_card_category_contain {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px 0px 0px;
    margin-bottom: 5px;
  }
  
  .notes_category_alert_info {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .notes_category_alert_info h6 {
    background-color: #e5e9fc;
    border-radius: 5px;
    color: #516beb;
    line-height: 22px;
    padding: 0px 5px;
  }
  
  .notes_category_alert_alert {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .notes_category_alert_alert h6 {
    background-color: #fff5da;
    border-radius: 5px;
    color: #ecab21;
    line-height: 22px;
    padding: 0px 5px;
  }
  
  .notes_category_alert_critical {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .notes_category_alert_critical h6 {
    background-color: #ffe9e8;
    border-radius: 5px;
    color: #fd372a;
    line-height: 22px;
    padding: 0px 5px;
  }
  
  .drawer_close_btn button {
    color: #787878;
  }
  
  .drawer_close_btn svg path {
    fill: #787878;
  }
  
  .drawer_close_btn svg {
    width: 9px;
    height: 9px;
    margin-right: 3px;
  }
  
  .drawer_add_btn button {
    color: #516beb;
  }
  
  .drawer_add_btn svg line {
    stroke: #5156be;
  }
  
  .drawer_add_btn svg {
    width: 16px;
    height: 16px;
  }
  
  .notes_icon_btn svg {
    width: 15px;
    height: 15px;
  }
  
  .notes_icon_btn svg path {
    fill: #4b4f72;
  }

  
// .cp_email_attachement {
//     padding: 5px;
//     background: #ffffff;
//     border: 1px solid #cdced9;
//     box-shadow: #00000024 0px 4px 4px;
//     border-radius: 5px;
//   }
  
  .cp_email_attachement p {
    cursor: pointer;
// padding: 5px;
  }
  
  .cp_email_attachement p:hover {
    background-color: #ecf0fd;
    border-radius: 5px;
  }
  
  .dms_card_container {
    padding: 5px 0px;
    border: 1px solid #cdced9;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .ci_card_container {
    padding: 10px 0px;
    border: 1px solid #cdced9;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .dms_card_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 5px;
  }
  
  .dms_card_doc_type_content {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .file_text_wraper {
    position: absolute;
    top: 6px;
    font-size: 6px;
    left: 7px;
    font-weight: 900;
  }
  
  .file_wraper_icon {
    position: relative;
    color: #ffffff;
  }
  
  .--doc_purple svg path {
    fill: #6f1ab6;
  }
  
  .--doc_green svg path {
    fill: #0ac963;
  }
  
  .--doc_blue svg path {
    fill: #0263d1;
  }
  
  .--doc_grey svg path {
    fill: #ededed;
  }
  
  .--doc_grey p {
    color: #333860;
    font-weight: 900;
  }
  
  .--doc_yellow svg path {
    fill: #fff89c;
  }
  
  .--doc_yellow p {
    color: #333860;
    font-weight: 900;
  }
  
  .--doc_font_size {
    font-size: 5px;
  }
  
  
  
  
  
 
  
  

  
  // @media (max-width:1260px) {
  //   .cp__chart__wraper{
  //     width: 174px !important;
  //   }
  // }
  
 
  

