.message-container {
  padding: 0px 30px 0px 15px;
  margin-bottom: 10px;
}
.message-content {
  display: flex;
  width: 423px;
  border: 0.5px solid #cdced9;
  box-shadow: 1px 2px 4px 0 #33386025;
  padding: 20px;
  border-radius: 5px;
  flex-direction: row;
  margin-bottom: 10px;
}
.message-content__bottom-expand {
  display: flex;
  align-items: end;
}
.message-container__sender-card {
  padding: 0px 30px 0px 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.--sender-bg {
  background-color: #f0f3f8;
}
.message-footer__send-btn {
  background-color: #516beb;
  height: 36px;
  width: 40px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-header__mobile-view {
  display: none;
}
.logo-max {
  padding-top: 6px;
}

.header_payabel {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  text-align: center;
}
.header_payabel span {
  font-weight: 400;
  font-size: 20px;
  padding-right: 5px;
}

.sidebar-bottom {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
  // position: absolute;
  right: 1%;
}

.sidebar-bottom img {
  filter: opacity(28%);
}

.sidebar-bottom:hover img {
  filter: opacity(100%);
}

.cus-pay-lab-head {
  font-size: 16px;
}

@media (min-width: 360px) and (max-width: 415px) {
  .logo-max {
    padding-top: 0px;
  }
  .cus-pay-lab-head{
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
  }
  .message-content {
    width: auto;
  }
  .message-footer__send-btn {
    width: 50px;
  }
  .--hidden-top {
    display: none;
  }
  .message-header__mobile-view {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .header_payabel {
    padding: 0px 20px !important;
    // justify-content: flex-start;
    text-align: center;
  }
  .header_payabel span {
    font-weight: 400;
    font-size: 15px !important;
    padding-right: 5px;
  }
  .invoices-header___message-conatiner {
    padding: 14px 10px !important;
  }
  .sidebar-bottom {
    display: none;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
    position: absolute !important;
    right: 3% !important;
    bottom: -2px !important;
  }
}
