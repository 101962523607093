.layout-container {
  // display: flex;
  max-width: 155vh;
  margin: auto;
  // height: 100vh;
  // flex-direction: row;
  height: 100%;
}

.text {
  white-space: nowrap;
  padding-right: 15px;
}

div.button-tilt {
  overflow: hidden;

  .label-hidden {
    max-width: 0px;
    opacity: 0;
    max-height: 1em;
    white-space: nowrap;
    transition: 0.2s;
  }

  &:hover {
    .label-hidden {
      max-width: 200px;
      margin-left: 8px;
      opacity: 1;
    }
  }
}

.payment-card__mobile-view {
  display: none;
}

.invoices-container {
  flex: 1;
  background-color: #ffffff;
  // padding: 25px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  height: calc(100vh - 52px);
  // width: 45vw;
}

.invoices-title {
  // margin-top: 25px;
  text-align: center;
  margin-bottom: 25px;
}

.datechoose-title {
  // margin-top: 25px;\
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.invoices-title__color {
  // color: #516beb;
  font-size: 18px;
}

.invoices-filters {
  display: flex;
  margin-bottom: 25px;
}

.invoices-filters__tag {
  display: flex;
  padding: 10px;
  align-items: center;
  border: 1px solid #516beb;
  border-radius: 5px;
  margin-left: 20px;
}

// table style

.customer-profile-tab__table {
  /* margin-top: 40px; */
  display: block;
  position: relative;
  // overflow-x: scroll;
  // overflow-y: scroll;
  /* height: 458px; */
  /* height: 72%; */
  // text-align: center;
}

.cp-tab__table-wrapper {
  width: 100%;
  // display: table;
}

.pay-installment-new-container {
  padding: 25px;
  // minHeight: 768px;
  height: 350px;
  overflow: scroll;
}

.cp-tab__table-wrapper {
  overflow-x: auto;
  width: 100%;
  position: relative;
}

.cp-tab__table-header,
.cp-tab__table-body {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.cp-tab__table-header-content,
.cp-tab__table-body-content {
  display: table-row;
}

.cp-tab__table-header-cell,
.cp-tab__table-body-cell {
  display: table-cell;
  width: "140px";
}

.cp-tab__table-wrapper--credit {
  height: 100%;
  width: 100%;
  display: table;
}

.cp-tab__table-header-cell {
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: justify;
  width: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cp-tab__table-header-cell--select {
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: justify;
  width: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100px !important;
}

.cp-tab__table-header-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #f8f8fb;
  font-size: 20px;
  // border: 1px solid #cdced9;
  border-radius: 5px;
}

.cp-tab__table-header-wrapper--credit {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}

.cp-tab__table-header-content {
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // padding: 0px 10px;
}

.cp-tab__table-header-content-paid {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.cp-tab__table-header-cell-content:hover .drag-drop svg ellipse {
  opacity: 1;
  transition: opacity 1s;
}

.cp-tab__table-header-cell-content {
  height: 100%;
  display: flex;
  position: relative;
  // flex: 1;
}

.cp-tab__table-header-cell-content:hover .btn-boter {
  border-right: 0.3px solid #d6d6d6;
  transition: 0.5ms;
}

.cp-tab__table-body-content {
  display: table-row;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  gap: 18px;
}

.cp-tab__table-body-content-paid {
  display: table-row;
  /* margin-top: 82px; */
  border-bottom: 0.5px solid #cdced9;
  /* border-radius: 5px; */
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  height: 60px;
  gap: 40px;
  /* overflow: hidden; */
  padding: 0px 10px;
}

.cp-tab__table-body-content--credit {
  display: table-row;
  //  margin-top: 25px;
  // border-bottom: 0.5px solid #cdced9;
  /* border-radius: 5px; */
  justify-content: center;
  align-items: center;
  // padding: 5px;
  text-align: center;
  background-color: #fff;
  height: 50px;
  /* overflow: hidden; */
  gap: 20px;
}

.cp-tab__table-body {
  // display: table-row-group;
  // height: calc(100vh - 300px);
  height: 40vh;
  // overflow: scroll;
  // overflow-x: hidden;
  // overflow-y: scroll;
  padding: 0px 10px 10px 10px;
}

.cp-tab__table-body-cell {
  width: 45px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.cp-tab__table-body-cell--credit {
  height: 40px;
  width: 130px !important;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 5px;
  border: 0.5px solid #cdced9;
  margin-top: 15px;
}

.invoices-footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  height: 107px;
}

// .invoice-table-wraper {
//   height: 95vh;
// }

.table-cell__type-duble-value {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 300px;
}

.table-cell__type-amount {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 475px;
}

// .invoices-header-container{

// }
.invoices-header___message-conatiner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  box-shadow: 0px -5px 20px 0px #33386010;
  background-color: #ffffff;
}

.invoices-header___nav-buttons {
  display: flex;
  gap: 3px;
  cursor: pointer;
}

.req-call__send-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: #f9f9f9;
  height: 75px;
}

.req-time__input-wrap {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.req-time__mobile-sent-btn {
  display: none;
}

.request-call-contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.request-call-contact-us-sub-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin-top: 5px;
}

.request-call-contact-us-svg {
  display: flex;
  width: 50px;
  height: 50px;

  border-radius: 50%;
  text-align: center;
  z-index: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.request-call-contact-us-content {
  display: column;
  background-color: #fafafa;
  width: 140px;
  padding: 5px 0px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #cdced9;
  border-bottom: 1px solid #cdced9;
  border-right: 1px solid #cdced9;
  height: 45px;
  border-top-right-radius: 5px;
  // borderBottomLeftRadius: 5px;
  border-bottom-right-radius: 5px;
  // borderTopLeftRadius: 5px;
  margin-left: -12px;
}

.call-back-contact-separtor {
  text-align: center;
  color: #adadad;
  font-weight: 300;
  // margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 54%;
}

.call-back-contact-separtor::before,
.call-back-contact-separtor::after {
  flex: 1;
  content: "";
  padding: 0.5px;
  background-color: #c7c7c7;
  margin-right: 8px;
  margin-top: 25px;
  margin-left: 8px;
  margin-bottom: 25px;
}

.mobile-view__sidebar {
  display: none;
}

.mobile-view__sidebar {
  position: fixed !important;
  bottom: 0 !important;
  z-index: 99;
  height: auto;
  padding: 0px;
}

// .invoice-table__wraper {
//   height: 95%;
//   overflow: scroll;
// }

.mobile-view__footer {
  display: none;
}

.time-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid #516beb;
  border-radius: 5px;
  padding: 6px;
  gap: 5px;
  margin-bottom: 15px;
  background-color: #eeeeee;
}

.table-main__head-cell_content--left-align {
  text-align: left;
}

.table-main__head-cell_content--right-align {
  text-align: right;
}

.credit_href--mobile:hover {
  color: #516beb;
}

.credit_href--web {
  color: #5b5b74;
}

// .credit_href--web:hover {
//   color: #ffffff;
// }
// .credit_href--web svg path {
//   fill: #ffffff;
// }
// .credit_href--web:hover svg path {
//   fill: #ffffff;
//   fill-opacity: 100;
// }

.rq__call__form__wraper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;
  // margin: auto;
}

.back__btn-comp {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 2%;
}

.--modifier {
  justify-content: start;
  width: 45%;
  align-items: center;
}

.due-sort {
  padding: 15px;
  padding-left: 0px;
  padding-right: 0px;
}

.req__show_d {
  display: flex;
  gap: 20px;
  align-items: center;
}

.org-name {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.customer_portal_logo {
  object-fit: contain;
  width: auto;
  max-height: 40px;
}

.table-filter-area {
  width: 100%;
  height: 50px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
}

.down-back-btn {
  display: none;
}

.header-changes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

.rpt_val {
  display: flex;
  // gap: 20px;
  padding: 0px 35px;
  flex-direction: column;
  justify-content: center;
}

.rt-main_hdr {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cbd-back {
  display: none;
}

.rpt-svg-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rpt-svg-bottom-header {
  display: none;
}

.--web-view {
  display: block;
}

.new-mobile-view {
  display: none;
}

.no-records-mobile_view {
  display: none;
}

.org_name {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}

.payment-list-org_name {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}

.table-selected-mobile-footer {
  display: none;
}

.table-selected-web-view {
  display: block;
}

.totl-ots-mob {
  display: none;
}

.totl_ots-web {
  display: block;
  padding-top: 1px;
}

.email-btn {
  display: block;
}

.--payment_plan_tabel {
  height: calc(100vh - 120px) !important;
}

.header-bck {
  height: 30px;
  width: 30px;
  border: 0.5px solid #cdced9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.--req_modifi {
  justify-content: space-between;
}

// media start here

@media (min-width: 275px) and (max-width: 560px) {
  .email-btn {
    display: none;
  }

  .payment_list_container {
    width: 100% !important;
  }

  .--call_back_details {
    display: none !important;
  }

  .totl-ots-mob {
    display: block;
    white-space: nowrap;
    padding-top: 2px;
  }

  .totl_ots-web {
    display: none;
  }

  .table-selected-web-view {
    display: none;
  }

  .org_name {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 171px;
  }

  .payment-list-org_name {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 270px;
  }

  .due-sort {
    padding: 15px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .table-selected-mobile-footer {
    display: block;
  }

  .new-mobile-view {
    display: block;
  }

  .--web-view {
    display: none;
  }

  .desktop-view {
    display: none;
  }

  .rpt-svg-bottom-header {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .rpt-svg-header {
    display: none;
  }

  .cbd-back {
    display: block;
  }

  .--promise_details {
    display: block !important;
  }

  .rt-main_hdr {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rpt_val {
    display: flex;
    gap: 10px;
    padding: 0px;
  }

  .header-changes {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;
    justify-content: center;
    padding: 0px 20px;
  }

  .none {
    display: none !important;
  }

  .--modifier {
    justify-content: start;
    width: 100%;
    align-items: center;
  }

  .down-back-btn {
    display: block;
  }

  .top-back-btn {
    display: none;
  }

  .sort-field {
    display: none;
  }

  .table-filter-area {
    display: none;
  }

  .cp-tab__table-body {
    // display: table-row-group;
    height: calc(100vh - 300px);
    overflow: scroll;
  }

  div.button-tilt {
    overflow: hidden;

    .label-hidden {
      max-width: 0px;
      opacity: 0;
      max-height: 1em;
      white-space: nowrap;
      transition: 0.2s;
    }

    &:hover {
      .label-hidden {
        max-width: 0px;
        margin-left: 0px;
        opacity: 0;
      }
    }
  }

  .invoice-table__wraper {
    // height: calc(100% - 300px);
    overflow: scroll;
  }

  .layout-container {
    display: flex;
    max-width: 125vh;
    margin: 0px;
    height: 100%;
    flex-direction: column-reverse;
    position: relative;
    overflow: hidden;
  }

  .invoices-container {
    height: 93%;
    width: 100vw;
  }

  // .layout-container {
  //   flex-direction: column-reverse !important;
  // }

  .logo-align {
    display: none;
  }

  .sidebar-wrapper {
    flex-direction: row;
    width: 100%;
  }

  .sidebar-middle {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
  }

  .icon-child {
    flex-direction: column;
  }

  // .sidebar-wrapper {
  //   position: sticky;
  //   bottom: 0;
  //   width: 100%;
  // }

  .mobile-view__sidebar {
    display: block;
  }

  .sidebar-middle__menu {
    border-bottom: 0px;
  }

  .icon-child div {
    font-size: 12px;
    margin-left: 0px;
    font-weight: 400;
    color: #adadad;
    font-size: 10px;
  }

  // .sidebar-middle :nth-child(4) {
  //   display: none;
  // }

  // .sidebar-middle :nth-child(5) {
  //   display: none;
  // }
  // .sidebar-middle :nth-child(6) {
  //   display: none;
  // }
  // .sidebar-top {
  //   display: none;
  // }
  // .org-name {
  //   display: none;
  // }
  .sidebar-wrapper {
    background-color: #fcfcfc;
    box-shadow: 0px -5px 20px 0 #33386010;
  }

  .cp-tab__table-header {
    display: none;
  }

  .cp-tab__table-body-content {
    margin-bottom: 20px;
    border: 1px solid #cdced9;
    border-radius: 5px;
    margin: 0px 20px 20px 20px;
    box-shadow: 2.4px 3.2px 2.4px 0 #00000015;
    padding: 0px 15px;
  }

  .invoices-footer__container {
    display: none;
  }

  .cp-tab__table-body-cell {
    justify-content: start;
    flex: 1;
  }

  .table-cell__type-amount {
    flex: 1;
  }

  .req-time__input-wrap {
    flex-direction: column;
    gap: 0px;
  }

  .req-call__send-btn {
    display: none;
  }

  .req-time__mobile-sent-btn {
    justify-content: end;
    flex: 1;
    display: flex;
  }

  .table-cell__type-duble-value {
    width: auto;
  }

  .table-cell__type-amount {
    width: auto;
  }

  .cp-tab__table-body-content {
    height: auto;
  }

  .mobile-view__footer {
    display: block;
    // position: fixed;
    // bottom: 9%;
    // width: 100%;
  }

  .footer-wraper__text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
    background-color: #f8f9fe;
    gap: 5px;
  }

  .invoice-table-wraper {
    height: calc(100vh - 280px);
  }

  .invoice-table-wraper-mobile {
    // height: calc(100vh - 365px);
    height: calc(100vh - 369px);
  }

  .invoice-table-wraper-mobile-paid {
    height: calc(100vh - 217px);
  }

  .rq__call__form__wraper {
    width: 100%;
  }

  .req__show_d {
    flex-direction: column;
    gap: 20px;
    padding: 10px 40px;
  }

  // .--modifier {}

  // .--hide-amount {
  //   display: none;
  // }
}

.payment-offline_comment {
  width: 100%;
  padding-top: 10px;
}

.req_pay_now_container {
  padding: 14px 15px;
  border-radius: 5px;
}

.req_pay_now_wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.req_pay_now_btn {
  width: 170px;
}

.req_pay_now_wraper a {
  flex: 1;
  text-align: -webkit-right;
}

@media (min-width: 275px) and (max-width: 991px) {
  .payment_list_container {
    width: 100% !important;
  }

  .payment-offline_comment {
    width: 90% !important;
  }
}

@media (min-width: 179px) and (max-width: 580px) {
  .payment_back_btn {
    left: 6% !important;
  }

  .no-records-mobile_view {
    display: block;
  }

  .--chooseplanbtn {
    left: 4% !important;
  }

  .--request-call {
    left: 4% !important;
  }

  .datechoose_back_btn {
    left: 4% !important;
  }

  .req_pay_now_container {
    padding: 35px 15px;
    border-radius: 5px;
  }

  .req_pay_now_wraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .req_pay_now_wraper p {
    display: none;
  }

  .req_pay_now_wraper a {
    flex: 1;
    text-align: right;
  }

  .req_pay_now_btn {
    width: 100%;
  }
}

@media (max-width: 300px) {
  .payment_back_btn {
    left: 0% !important;
  }

  .--chooseplanbtn {
    left: 0% !important;
  }

  .datechoose_back_btn {
    left: 0% !important;
  }
}

@media (min-width: 581px) and (max-width: 991px) {
  .datechoose_back_btn {
    padding-top: 0.5%;
    cursor: pointer;
    position: absolute;
    left: 20% !important;
  }

  .--request-call {
    left: 18% !important;
  }
}

@media (min-width: 280px) and (max-width: 400px) {
  .pay-installment-new-container {
    height: 410px;
  }
}

@media (min-width: 400px) {
  .pay-installment-new-container {
    height: 500px;
  }
}

@media (min-width: 390px) and (max-width: 830px) {
  .pay-installment-new-container {
    height: 590px;
  }
}

@media (min-width: 414px) and (max-height: 900px) {
  .pay-installment-new-container {
    height: 630px;
  }
}

@media (max-width: 430px) and (min-height: 915px) {
  .pay-installment-new-container {
    height: 660px;
  }
}

@media (min-width: 360px) and (max-height: 740px) {
  .pay-installment-new-container {
    height: 500px;
  }
}

@media (min-width: 360px) and (max-height: 700px) {
  .pay-installment-new-container {
    height: 350px;
  }
}

/* Optional: Adjustments to min-height and max-height for better responsiveness */
@media (min-height: 500px) {
  .pay-installment-new-container {
    min-height: 500px;
  }
}

// .invoice-summary-main-table table th:last-child,
// .invoice-summary-main-table table td:last-child {
//   position: sticky !important;
//   right: 0; /* Use right for the last child */
//   z-index: 1;
//   // background-color: #fff;
// }

.invoice-summary-main-table table thead tr,
.invoice-summary-main-table table tbody tr {
  border-bottom: 1px solid #eeeeee;
}

.invoice-summary-main-table table thead tr {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
}

.invoice-summary-main-table table thead tr .col-fixed,
.invoice-summary-main-table table tbody tr .col-fixed {
  position: -webkit-sticky;
  position: sticky !important;
  right: 0;
  z-index: 1;
  box-shadow: inset 10px 0px 5px 0px rgba(197, 197, 197, 0.1);
}

.invoice-summary-main-table table tbody tr .col-fixed {
  background-color: #ffffff;
}

// .invoice-summary-main-table table th:first-child,
// .invoice-summary-main-table table td:first-child {
//   width: 50px; /* Adjust this value as needed */
//   min-width: 50px; /* Ensure the column does not shrink below this width */
//   max-width: 50px; /* Ensure the column does not grow above this width */
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   border: none;
// }

.invoice-summary-main-table table th {
  background-color: #f8f8fb;
  padding: 15px;
}

.invoice-summary-main-table table th,
.invoice-summary-main-table table td {
  min-width: 50px;
  /* Ensure the column does not shrink below this width */
  max-width: 150px;
  /* Ensure the column does not grow above this width */
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  // padding: 8px;
  text-align: center;
  border: none;
}

.invoice-summary-main-table {
  overflow-x: scroll;
  overflow-y: scroll;
  // max-height: 320px;
  height: calc(-400px + 100vh);
  border-collapse: collapse;
}

.invoice-summary-main-table table {
  width: 100%;

  border-collapse: collapse;
  border: none;
}

// .invoice-summary-main-table table tbody {
//   height: 300px;
//   // overflow-y: scroll;
//   // overflow-x: scroll;
// }

.paid-summary-main-table table th {
  min-width: 50px;
  /* Ensure the column does not shrink below this width */
  background-color: #f8f8fb;
  border: none;
  padding: 10px;
  height: 50px;
  font-size: 14px;
  text-align: center;
}

.paid-summary-main-table td {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px;
  // height: 50px;
  text-align: center;
  background-color: #fff;
  border: none;
}

.paid-summary-main-table table {
  width: 100%;
  height: 100%;
  margin-top: 15px;

  border-collapse: collapse;
  border: none;
}

.paid-summary-main-table {
  overflow-x: auto;
}

.invoice-details-scroll {
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-height: 400px;
  max-height: 700px;
  overflow: scroll;
}

@media (min-width: 270px) and (max-width: 340px) {
  .invoice-details-scroll {
    min-height: 525px;
  }
}

@media (min-width: 340px) and (max-width: 410px) {
  .invoice-details-scroll {
    min-height: 470px;
  }
}

@media (min-width: 430px) and (max-width: 500px) {
  .invoice-details-scroll {
    min-height: 470px;
  }
}

@media (min-width: 300px) and (min-height: 844px) {
  .invoice-details-scroll {
    min-height: 400px;
  }
}

.container-contact-customize {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.border-contact-customize {
  border-bottom: 0.5px solid #c7c7c7;
  opacity: 0.5;
  width: 16%;
  font-family: 400;
}

.content-contact-customize {
  padding-right: 12px; // Equivalent to theme.spacing(2)
  padding-left: 12px;
  font-weight: 400;
  font-size: 13px;
  opacity: 0.6;
  color: #adadad;
}

// .back__btn-comp {
//   overflow: scroll;
// }

.esign_content_conatiner {
  padding: 10px;
  width: 100%;
  overflow: scroll;
  height: calc(100vh - 200px);
}