.view-picker {
  background-color: #ffffff;
  padding: 20px 15px;
}

.view-picker__save-view-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
}

.view-picker__title {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}
