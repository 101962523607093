.unsubscribe-main-container {
  padding: 0px 200px;
}

.unsubscribe-container {
  text-align: center;
  background-color: #ffff;
  height: 100vh;
  overflow: scroll;
}

.unsub-header-container {
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid #cdcec9;
}

.unsun-label-one {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.unsun-label-two {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

// newly added style for eLink view

.elink_sub_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: calc(100vh - 52px);
}