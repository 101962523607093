// .lp-header-container {
//   background-color: #f8f8f8;
//   padding: 5px 100px;
//   align-items: center;
//   display: flex;
//   justify-content: space-between;
// }

.lp-header-links {
  display: flex;
  gap: 10px;
  align-items: center;
}

// .lp-header-logo-container {
//   display: flex;
//   align-items: center;
//   gap: 10px;
// }
.step-home-details {
  color: #c7c7c7;
  font-family: "Roboto", "Arial", sans-serif;
}
.lp-content-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px 100px 30px 100px;
  display: flex;
  flex-direction: column;
}

.lp-form-container {
  width: 343px;
}

.lp-faq-container {
  padding: 20px 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.lp-subcontent-container {
  display: flex;
  align-items: center;
  padding: 20px 100px;
}

.lp-content__modify h2 {
  width: 343px !important;
}

.lp-content__modify p {
  width: 343px !important;
}

.lp-header-links p:hover {
  color: #cdced9;
}

.lp_data_info_container {
  width: 400px;
  border: 1px solid #d4e6fc;
  border-radius: 5px;
  text-align: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 10px 0 rgba(35, 77, 180, 0.08);
  transition: transform 0.2s ease, opacity 1s ease;
}

.lp_sub_content_data_secure {
  width: 400px;
  text-align: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}
.lp_data_info_container:hover {
  transform: scale(1.02);
}

.lp_data_info {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.secure_content_faq_container {
  margin-top: 50px;
}

@media (min-width: 275px) and (max-width: 560px) {
  .lp-header-logo-container div {
    display: none;
  }

  .lp-header-logo-container {
    flex: 1;
    justify-content: center;
    min-width: 0px !important;
  }

  // .lp-header-links {
  //   display: none;
  // }

  .lp-form-container {
    width: auto;
  }

  .lp-form-container__modify {
    width: 343px !important;
  }

  // .lp-header-container {
  //   padding: 5px 20px;
  // }

  .lp-content-container {
    padding: 20px 30px 30px 30px;
  }

  .lp-faq-container {
    padding: 20px 30px;
  }

  .lp-content__modify h2 {
    width: 343px !important;
  }

  .lp-content__modify p {
    width: 343px !important;
  }
}

@media (min-width: 561px) and (max-width: 766px) {
}

@media (min-width: 767px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1200px) {
}

@media (min-width: 1551px) and (max-width: 2500px) {
}

.geeks {
  background: linear-gradient(
      45deg,
      rgba(245, 70, 66, 0.75),
      rgba(8, 83, 156, 0.75)
    ),
    url("https://www.copahost.com/blog/wp-content/uploads/2019/07/imgsize2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 280px;
  text-align: center;
  color: #fff;
}

.right-container {
  display: flex;
  width: 50%;
  background-color: purple;
  margin: 15px;
  border-radius: 10px;
}

//footer
.menu_footer {
  text-align: center;
  padding: 20px 0;
  /* background-color: #f9f9f9; */
  /* border-top: 1px solid #e0e0e0; */
}

.menu_footer_separator {
  margin: 0 auto;
  width: 80%;
  border-color: #d3d3d3;
}

.menu_footer_linkscontainer {
  margin: 10px 0;
  font-size: 14px;
  color: #a0a0a0;
}

.menu_footer_link {
  cursor: pointer;
  text-decoration: none;
  color: #a0a0a0;
  font-weight: 500;
}

.menu_footer_separatortext {
  margin: 0 10px;
  color: #d3d3d3;
}

.menu_footer_footertext {
  font-size: 12px;
  color: #a0a0a0;
  margin: 10px 0 0;
}

@media (max-width: 768px) {
  .right-container {
    display: none;
  }
}
.mobile-view-menu {
  display: none;
}
.nav-links-home {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  width: 75%;
}

.nav-linkss {
  text-decoration: none;
  color: white;
  margin: 0 10px;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: transparent;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.mobile-view-menu-icon {
  display: none;
}
.separtor_home_left_page {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.footer_home_v2 {
  width: 100%;
  height: 10%;
  color: #fff;
}
.footer_home_content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 50px;
  position: relative;
  display: flex;

  justify-content: space-evenly;
}
.footer-content-column {
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color: #fff;
}
.separtor_home_left_footer_page_mobile {
  display: none;
}
.footer-landing-page {
  display: flex;
  justify-content: center;
  gap: 25px;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 10px;
}

.separtor_home_faq_page_desktop {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 3%;
}

@media screen and (max-width: 768px) {
  .lp-header-logo-container {
    display: flex;
    justify-content: flex-start;
    min-width: 0px !important;
  }
  .nav-links-home {
    display: none;
  }
  .lp_data_info_container {
    width: 100%;
    border: 1px solid #cdced9;
    border-radius: 5px;
    text-align: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, opacity 1s ease;
  }
  .nav-links-home-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    left: 0;
    top: 75px;
    z-index: 1;
    transition: all 0.5s ease-out;
    width: 100%;
  }
  .nav-linkss {
    color: #fff;
    text-align: center;
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease;
  }
  .mobile-view-menu-icon {
    display: block;
    position: absolute;
    font-size: 30px;
    border: none;
    outline: none;
    top: 15px;
    right: 25px;
  }
  .separtor_landing_page {
    background-color: #ffffff;
    display: flex;

    flex-direction: column;
  }
  .separtor_home_right_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .separtor_home_left_page {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
    padding: 0;
    // padding-left: 70px;
  }
  .footer_home_content {
    padding: 40px 15px 50px;

    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
  }
  .footer-content-column {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #fff;
  }
  .separtor_home_left_footer_page_mobile {
    padding-bottom: "30px";
    background-color: #fff;
    display: block;
  }
  .separtor_home_faq_page_desktop {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 50px;
    padding: 0;
    margin-top: 16%;
  }
  .footer-landing-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    align-items: center;
  }
  .secure_content_faq_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
  }
}

.footer-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}

.separtor_landing_page {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-right: 60px;
  padding-left: 60px;
  padding-top: 70px;
  padding-bottom: 50px;
  width: 100%;
}

@media (min-width: 760px) {
  .footer-wave-svg {
    height: 50px;
  }
}
.footer-menu-name {
  color: #fffff2;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
.footer-call-to-action-button:hover {
  background-color: #fffff2;
  color: #00bef0;
}
.footer-call-to-action-button {
  background-color: #155fb3;
  border-radius: 21px;
  color: #fffff2;
  display: inline-block;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  padding: 12px 30px;
  margin: 24px 10px 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s;
  cursor: pointer;
  position: relative;
}
