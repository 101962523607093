.payment-option__card {
  width: 300px;
  // height: 130px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px 5px 5px 10px;
  // margin-right: 10px;
  border: 0.5px solid #cdced9;
  box-shadow: 0px 13px 27px -5px #32325d0a;
}

.payment-option__card-content {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  // align-items: center;
}

.payment-card__wraper {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 25px;
}

.payment-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 20px;
  overflow: scroll;
  // height: calc(100vh - 65px);
}

.isuparrow {
  // width: 30px;
  // border: 1px solid #000;
  display: flex;
  justify-content: start !important;
  transform: rotate(180deg);
}

.payment-details__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 0.5px solid #cdced9;
  align-items: center;
}

.--input_field {
  flex-direction: column;
}

.payment--active {
  box-shadow: 0px 8px 16px -8px #00000030;
}

.payment-option__card svg path {
  fill: #adadad;
}

.payment--active svg path {
  fill: #0fb158;
}

// style for account info start here

.payment-text__conatiner {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
}

.payment-number__conatiner {
  display: flex;
  gap: 10px;
  align-items: center;
}

.payment_logo_wraper {
  width: 20%;
  // height: 40px;
  border-radius: 50px;
  // border: 1px solid #cdced9;
}

.payment_title {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  // border-bottom: 0px solid rgb(205, 206, 217);
  position: relative;
  // border-bottom: 1px solid #c7c7c7;
  justify-content: space-between;
  align-items: center;
}

// .payment_back_btn {
//   height: 30px;
//   width: 30px;
//   border: 0.5px solid #cdced9;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   position: absolute;
//   left: 25%;
// }

.payment_back_btn {
  padding-top: 0.5%;
  cursor: pointer;
  position: absolute;
  left: 27%;
}

.--request-call {
  padding-top: 0.5%;
  cursor: pointer;
  position: absolute;
  left: 33%;
}

.payment_plan_back_btn {
  padding-top: 0.5%;
  cursor: pointer;
  position: absolute;
  left: 20%;
}

.datechoose_back_btn {
  padding-top: 0.5%;
  cursor: pointer;
  position: absolute;
  left: 33%;
}

.--promise_details {
  display: none;
}

.payment_list_wraper .payment_list_value {
  display: none;
}

// .paymentplan_tablebody tr:nth-child(even) {
//   background-color: #cdced9d2;
// }
.payment_list_container {
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 37%;
  gap: 11px;
}

.--for_scroll_payment {
  overflow: scroll;
  height: calc(100vh - 225px)
}

.--payment__modifer {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 20px;
}

.payment__processing {
  display: flex;
  // gap: 20px;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.img_wraper_content {
  display: none;
}

.--payment_status {
  // justify-content: center;
  width: 100%;
  height: 100%;
}

.payment-list {
  display: flex;
  gap: 15px;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 3px 4px 0px #516beb25;
  border-radius: 5px;
  width: 300px;
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.none_both {
  display: none !important;
}

@media (min-width: 350px) and (max-width: 484px) {
  // .payment-section {
  //   display: none;
  // }

  .payment-list {
    width: 100%;
  }

  .card_header_text_wraper p {
    font-size: 16px;
  }

  .card_header_text_wraper span {
    font-size: 16px !important;
  }

  .select_plan_amt_mobile div {
    font-size: 16px;
  }

  .img_wraper_content {
    display: block;
    width: 15%;
  }

  .payment-section {
    height: calc(100vh - 150px);
  }

  .payment-details__content {
    display: none;
  }

  .payment-card__mobile-view {
    display: flex;
    flex-direction: column;
    margin: 20px;
    border: 1px solid #cdced9;
    border-radius: 5px;
  }

  .payment-card__inner-hold {
    padding: 10px 10px 0px 10px;
  }

  .payment-card__due-invoices {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 40px;
    align-items: center;
    border-bottom: 0.5px solid #e7e7e7;
  }

  .payment-card__footer-area {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 55px;
    align-items: center;
    padding: 12px 12px 12px 12px;
    background-color: #f0f3f8;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #cdced9;
  }

  .payment-card__adhoc-card {
    padding: 20px 10px;
  }

  .payment-adhoc__content-wraper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 15px;
  }

  .payment_list_wraper .payment_list_value {
    display: block;
  }

  .--payment_dtl_hide p {
    display: none;
  }

  .payment_title {
    align-items: flex-start;
    padding: 0;
    border-bottom: 0px solid #c7c7c7;
    flex-direction: row;
  }

  .--mobl_title {
    align-items: center;
    margin-bottom: 25px;
    margin-top: 10px;
  }

  .payment_head {
    border-bottom: 1px solid #c7c7c7;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 4px;
  }

  .payment__processing {
    display: flex;
    // gap: 20px;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .payment-card__footer-area--modify {
    display: flex;
    height: 55px;
    align-items: center;
    padding: 12px 12px 12px 12px;
    background-color: #f0f3f8;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #cdced9;
    justify-content: end;
  }
}

.payment_list_dtl_container {
  width: 55%;
}

.pplist_date {
  height: calc(100vh - 260px);
  overflow: scroll;
}

.pplist_date--modifi {
  height: calc(100vh - 440px);
}

.coupon-card {
  background: #FAFAFA;
  color: #fff;
  text-align: center;
  padding: 10px 22px;
  border-radius: 10px;
  // box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  border: 1px solid #CDCED9;
  width: 400px;

}

.circle1,
.circle2 {
  background: #ffffff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

}

.circle1 {
  left: -10px;
  border-right: 1px solid #cdced9;
}

.circle2 {
  right: -10px;
  border-left: 1px solid #cdced9;
}

.loader_dot {
  width: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 10px 0 #000, -10px 0 #0002;
    background: #000
  }

  33% {
    box-shadow: 10px 0 #000, -10px 0 #0002;
    background: #0002
  }

  66% {
    box-shadow: 10px 0 #0002, -10px 0 #000;
    background: #0002
  }

  100% {
    box-shadow: 10px 0 #0002, -10px 0 #000;
    background: #000
  }
}

.payment_type div {
  margin-bottom: 0px !important;
  text-align: left;
}

.esign_container {
  justify-content: start;
  padding: 15px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}