.bubble-container {
    display: flex;
}

.bot {
    justify-content: flex-start;
}

.user {
    justify-content: flex-end;
    border-radius: 20px !important;
    border-bottom-right-radius: 2px !important;
}

.chat-bubble {
    /* background-color:#E6F8F1; */
    padding: 10px 21px;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomleft: 2px;
    border-radius: 20px;
    border-bottom-left-radius: 2px;
    /* display:inline-block; */
    display: flex;
    gap: 5px;
    border: 1px solid var(--primary);
    max-width: 250px
}

.chat-bubble .bot {
    border: 1px solid var(--primary);
    width: "70%";
}

.chat-bubble .user {
    background-color: var(--primary);
    border-radius: 20px;
    border-bottom-right-radius: 2px;
    color: #ffffff;
}

.chat-bubble p {
    color: var(--primary);
    padding-right: 10px;
    font-size: 13px;
}

.bubble-info {
    font-size: 11px;
    margin-bottom: 8px;
    margin-left: 5px;
    display: flex;
}

.bubble-info .bot {
    text-align: left;
}

.bubble-info .user {
    text-align: right;
}

.chat-button {
    display: flex;
    justify-content: center;
    border: 2px solid var(--primary);
    width: 75%;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    background-color: #f4f4f4;
}

.chat-button p {
    text-align: center;
    color: var(--primary);
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
}

.typing {
    align-items: center;
    display: flex;
    height: 17px;
}

.typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: var(--background);
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
}

.typing .dot:nth-child(1) {
    animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
    animation-delay: 400ms;
}

.typing .dot:nth-child(3) {
    animation-delay: 600ms;
}

.typing .dot:last-child {
    margin-right: 0;
}

@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color: var(--primary);
    }

    28% {
        transform: translateY(-7px);
        background-color: var(--primary);
    }

    44% {
        transform: translateY(0px);
        background-color: var(--primary);
    }
}

.inputContainer {
    width: 100%;
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    border-top: 1px solid #cdced9;
}

.chatContainer {
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* height: 400px; */
    gap: 0.25rem;
    /* flex: 1 1 0; */
    height: calc(100vh - 360px);
}


.inputText {
    flex: 1;
    padding: 0.5rem;
    border: 2px solid #b3194200;
    border-radius: 12px;
    font-size: 16px;
}

.sendButton {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 12px;
    background-color: var(--primary);
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sendButton:hover {
    filter: brightness(90%);
}

.messageTimeContainer {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    word-wrap: break-word;
}

.messageBubble {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 400;
    word-wrap: break-word;
    position: relative;
}

.userMessageAlign {
    align-self: flex-end;
}

.botMessageAlign {
    align-self: flex-start;
}

.userMessage {
    background-color: #b31942;
    color: white;
}

.botMessage {
    background-color: #f0f0f0;
    color: #333;
}

.inputContainer textarea:focus {
    outline: none;
}

.inputContainer textarea {
    resize: none;
}