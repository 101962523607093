$primary: #516beb;
$secondary: #fcc312;
$surface: #f0f3f8;
$background: #eeeeee;
$border: #cdced9;
$table-background-color: #f5f6fa;
$white: #ffffff;
$text-color: #333860;
$drag-drop-color: #d6d6d6;
$elevation-1: 1px 2px 4px 0px #33386025;
$elevation-2: 0px 4px 4px 0px #33386025;
$elevation-3: 3px 5px 5px 0px #33386025;
$expand-row-color: #f3f3f3;
$expand-row-bottom: #fbfbfb;
$expand-text-color-bottom: #e1563c;

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --surface: #{$surface};
  --background: #{$background};
  --border: #{$border};
  --table-background-color: #{$table-background-color};
  --white: #{$white};
  --text-color: #{$text-color};
  --drag-drop-color: #{$drag-drop-color};
  --elevation-1: #{$elevation-1};
  --elevation-2: #{$elevation-2};
  --elevation-3: #{$elevation-3};
  --expand-row: #{$expand-row-color};
  --expand-row-bottom: #{$expand-row-bottom};
  --expand-text-color-bottom: #{$expand-text-color-bottom};
}

:export {
  PRIMARY_COLOR: $primary;
  SECONDARY_COLOR: $secondary;
  SURFACE_COLOR: $surface;
  BACKGROUND_COLOR: $background;
}
