.usc-landing-container {
    text-align: left;
    padding: 0px 30px 30px 30px;
}

.usc_list_ul li {
    font-size: 16px;
    margin-left: 30px;
}

.faq_container {
    padding: 10px;
    border-bottom: 1px solid #cdced9;
    border-radius: 5px;
    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    cursor: pointer;

}

.active_effect {
    -webkit-transition: max-height 1s;
    -moz-transition: max-height 1s;
    -ms-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    height: 200px;
}

.faq_main_cointainer {
    border-radius: 5px;
    -webkit-box-shadow: -2px 22px 39px -11px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: -2px 22px 39px -11px rgba(0, 0, 0, 0.38);
    box-shadow: -2px 22px 39px -11px rgba(0, 0, 0, 0.38);
}

.faq_title_container {
    display: flex;
    justify-content: space-between;

}