.customize-ui-container {
  text-align: center;
  background-color: #ffff;
  height: calc(100vh - 50px);
  overflow: scroll;
}

.customize-ui-container--modify {
  text-align: center;
  background-color: #ffff;
  height: calc(100vh - 100px);
  overflow: scroll;
}

.customize-ui-main-container {
  padding: 0px 200px;
}

.customize-icon-header {
  padding: 20px 20px 5px 20px;
}

.customize-welcome-text {
  padding: 10px 40px 15px 40px;
  // padding-bottom: 15px;
}

.customize-welcome-text--modify {
  padding: 35px 40px 15px 40px;
}

.org-name-head-line {
  font-size: 16px;
}

.customize-ui-btns {
  padding: 0 110px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.customize-ui-amnt {
  padding-bottom: 15px;
}

.summery-action-view-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.icon-border {
  border: 1px solid #cdced9;
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.summery-action-view-labels {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 5px 5px 15px;
}

.compliance-info-section {
  padding: 20px 20px;
  // margin-top: 10px;
}

.cust-border-line {
  display: flex;
  align-items: center;
  padding: 10px 90px;
  gap: 10px;
}

.action_wraper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 5px;
}

.pay_installment_btn {
  color: #ffffff;
  border: 1px solid #ffffff;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 8px;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
}

.pay_installment_btn:hover {
  background-color: #2f4f7f30;
}

.welcome_text_child div {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px,
}

.welcome_text_child span {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px,
}

@media (max-width: 376px) {

  .compliance-info-section {
    padding: 2px 17px;
    height: 120px !important;
    overflow-y: auto;
    margin-top: 10px;
  }
}

@media (min-width: 275px) and (max-width: 560px) {
  .customize-ui-main-container {
    padding: 0 0px;
  }

  .org-name-head-line {
    font-size: 13px !important;
    flex: 1;
    justify-content: flex-start;
    display: flex;
  }

  .customize-icon-header {
    padding: 60px 6px 6px 6px;
  }

  .customize-ui-btns {
    padding: 0px 50px;
  }

  .customize-welcome-text {
    padding: 10px 20px 15px 20px;
  }

  .cp-tab__table-body-conten {
    gap: 10px !important;
  }

  .compliance-info-section {
    padding: 2px 17px;
    // height:  120px !important;
    // overflow-y: auto;
    margin-top: 10px;

  }

  .header_payabel {
    padding: 0px 20px !important;
    // justify-content: flex-start;
    text-align: center;
  }

  .cust-border-line {
    padding: 10px 40px;
  }
}

@media (min-width: 561px) and (max-width: 766px) {
  .customize-ui-main-container {
    padding: 0px 50px;
  }

  .customize-ui-btns {
    padding: 0px 50px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .customize-ui-main-container {
    padding: 0px 80px;
  }

  .customize-ui-btns {
    padding: 0px 150px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .customize-ui-main-container {
    padding: 0px 150px;
  }

  .customize-ui-btns {
    padding: 0px 150px;
  }
}

@media (min-width: 1551px) and (max-width: 2500px) {
  .customize-ui-main-container {
    padding: 0px 250px;
  }

  // .customize-ui-btns {
  //   padding: 0px 150px;
  // }
}