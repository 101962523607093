@import "./variables";
@import "./invoices.scss";
@import "./payments.scss";
@import "./messages.scss";
@import "./components/index.scss";
@import "./iconStyle.scss";
@import "./favIconStyleChanges.scss";
@import "./downloadButtonMobile.scss";
@import "./downloadTextBox.scss";
@import "./documentIcon.scss";
@import "./customizeUi.scss";
@import "./unsubscribe.scss";
@import "./usc.scss";
@import "./homev2.scss";
@import "./chatBot.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sacramento&display=swap");

a {
  color: inherit;
  text-decoration: inherit;
}

.icon-button.primary {
  &svg path {
    fill: var(--text-color);
  }

  &:hover svg path {
    fill: var(--white);
  }
}

.icon-button.tertiary {
  &svg path {
    fill: var(--text-color);
  }

  &:hover svg path {
    fill: var(--primary);
  }
}

.text-primary {
  color: var(--primary) !important;
}

.text-error {
  // color: var(--primary) !important;
  color: #fd372a !important;
}

.action-container {
  background-color: var(--white);
  box-shadow: var(--elevation-2);
  width: 200px;
  border-radius: 5px;
  border: 0.5px solid var(--border);
  padding: 20px 20px;
  text-align: center;
}

.action-container-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  & button {
    margin: 8px;
  }
}

.pb-10 {
  padding-bottom: 10px;
}

.font-icon-color::before {
  color: var(--primary);
}

.paymentPlan {
  width: 30%;
}

.paymentAmount {
  width: 20%;
}

.planned_amount-table {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;

  table {
    border-collapse: separate;
    border-spacing: 0;
    // overflow: hidden;

    thead {
      // background-color: #f5f5f5;

      th:first-child {
        border-top-left-radius: 7px;
      }

      th:last-child {
        border-top-right-radius: 7px;
      }
    }

    tbody {
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 8px;
        }

        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}

.--modifi_table {
  display: inline-block !important;
}

.monthly_pay {
  width: 40%;
  padding: 0px 20px;
}

.planned_pay {
  position: fixed;
  bottom: 0%;
  width: 100%;
  height: auto;
  padding: 3% 0px;
  border-radius: 10px;
  border-top: 1px solid #cdced9;
  border-bottom: none;
  background: #fff;
}

.mobile_manual_pay-btn {
  display: none;
}

.tab_manual_pay {
  padding: 15px;
  text-align: end;
}

@media (min-width: 100px) and (max-width: 991px) {
  .planning_mobiledates {
    display: block !important;
    width: 100%;
  }

  .tab_manual_pay {
    display: none !important;
  }

  .mobile_manual_pay-btn {
    display: block !important;
  }

  .planning_dates {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    gap: 5px;
  }

  .planned_date_select {
    padding: 0px !important;
    width: 78% !important;
  }
}

@media (min-width: 100px) and (max-width: 775px) {
  .monthly_pay {
    width: 100%;
    padding: 10px 20px;
  }

  // .--changed {
  //   left: 10%
  // }

  .--changeds {
    left: 10%;
  }

  .--paymentplanrecipt {
    width: 40% !important;
  }
}

@media (min-width: 500px) and (max-width: 992px) {
  .table_mobile {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    padding: 5px 30px;
    border-top: 1px solid #cdced9;
  }

  // .go_back-btn {
  //   display: none;
  // }
}

@media (min-width: 100px) and (max-width: 500px) {
  .--paymentplanrecipt {
    width: 83% !important;
  }

  .planned_amount {
    padding-top: 5px;
  }

  .date-selecter_img {
    width: 70% !important;
  }

  .invoice-header_mobile {
    display: block !important;
  }

  .--planned_details {
    display: none;
  }

  .paymentPlan {
    width: 70%;
  }

  .monthly_pay {
    width: 100%;
    padding: 10px 20px;
  }

  .amount_monthly {
    max-height: 120px !important;
    overflow-y: scroll;
  }

  .table_mobile {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    padding: 5px 30px;
    border-top: 1px solid #cdced9;
  }

  .planned {
    width: 77% !important;
  }

  .planned_date_select {
    padding: 0px !important;
    width: 78% !important;
  }
}

.invoice-header_mobile {
  display: none;
}

.planning_mobiledates {
  display: none;
}

.mobile_table {
  display: none;
}

.--table_width {
  width: 100%;
}

// .tableFixHead {
//   overflow-y: auto;
//   height: 200px;
// }
.existing_payment_plan {
  height: calc(100vh - 285px);
  overflow-y: auto;
}

.existing_payment_plan--modify {
  height: calc(100vh - 315px);
  overflow-y: auto;
}

thead th {
  position: sticky;
  top: 0px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px 16px;
  color: #333860;
  border: 1px solid#CDCED9;
}

th {
  color: #787878;
  background: #eef0f2;
}

.KEPT {
  background-color: #0fb158;
  border-radius: 50%;
  color: #0fb158;
  padding: 3px;
  width: 2px;
  height: 2px;
}

// .Partial,
.IN_PROGRESS {
  background-color: #8b8a8a;
  border-radius: 50%;
  color: #8b8a8a;
  padding: 3px;
  width: 2px;
  height: 2px;
  border: none;
}

.IA {
  background-color: #8b8a8a;
  border-radius: 50%;
  color: #8b8a8a;
  padding: 3px;
  width: 2px;
  height: 2px;
  border: none;
}

.PARTIAL {
  background-color: #fbbd04;
  border-radius: 50%;
  color: #fbbd04;
  padding: 3px;
  width: 2px;
  height: 2px;
  border: none;
}

.CANCELED {
  background-color: #fd372a;
  border-radius: 50%;
  color: #fd372a;
  padding: 3px;
  width: 2px;
  height: 2px;
}

.BROKEN {
  background-color: #fd372a;
  border-radius: 50%;
  color: #fd372a;
  padding: 3px;
  width: 2px;
  height: 2px;
}

.states {
  display: flex;
  align-items: center;
  gap: 3px;
}

.planned_amount {
  width: 100%;
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.--paymentplanrecipt {
  width: 40%;
  // padding: 15px 20px 10px 20px;
  text-align: start;
  // background-color: #f0f5ff;
  min-height: 75px;
  max-height: 350px;
  overflow: hidden;
}

.amount_monthly {
  max-height: 275px;
  overflow-y: scroll;
}

.monthly_label {
  display: flex;
  gap: 4px;
  line-height: 25px;
}

.planning_dates {
  width: 100%;
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.planned {
  width: 40%;
}

.planned_date_select {
  width: 20%;
}

.discountCard {
  padding: 7px 15px;
  position: absolute;
  background-color: #d93574;
  top: 0px;
  font-size: 10px;
  color: #fff;
  box-shadow: 1px 2px 4px rgba(51, 56, 96, 0.25);
  right: 0%;
  border-radius: 0px 5px;
}

.buildyourplan {
  border-radius: 5px;
  border: 1px solid #cdced9;
  // background: #cdced9;
  // width: 50%;
  flex: 1;
  /* shadow */
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 2px 4px 0px rgba(51, 56, 96, 0.25);
  cursor: pointer;
}

.buildyourplan_subhead {
  color: #333860;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.plannedamountpending {
  height: auto;
  border-radius: 10px;
  border-top: 1px solid rgb(205, 206, 217);
  width: 100%;
}

// .payment_porate {
//   height: 170px;
//   overflow: scroll;
// }

// @media (min-width:1400px) and (max-width:1500px) {
//   .payment_porate {
//     height: 190px;
//   }
// }

// @media (min-width:1800px) {
//   .payment_porate {
//     height: 390px;
//   }
// }

// @media (min-width:2400px) {
//   .payment_porate {
//     height: 430px;
//   }
// }

@media (max-width: 991px) {
  .buildyourplan {
    width: 90% !important;
  }

  .paymentAmount {
    display: none;
  }

  // .payment_porate {
  //   height: 10px;
  // }

  .planned_amount-table {
    display: none !important;
  }

  .--modifi_table {
    display: inline-block !important;
  }

  .plannedamountpending {
    display: none !important;
  }

  .table-selected-web-view {
    display: none !important;
  }

  .mobile_table {
    display: block !important;
    margin: 30px 0px;
  }

  .chooseplan_card {
    width: 90% !important;
  }

  .chooseplan_card_quick_one {
    width: 90% !important;
  }

  .chooseplan_card-dis {
    width: 90% !important;
  }

  .data_selecter {
    height: 100% !important;
  }

  .date_submit {
    display: none !important;
  }
}

.chooseplan_card {
  width: 50%;
  border-radius: 5px;
  border: 1px solid #cdced9;
  background: #fff;
  // padding: 15px 15px 0px 0px;
  position: relative;
  overflow: hidden;
  margin: 10px 0px;
}

.chooseplan_card_quick_one {
  width: 50%;
  border-radius: 5px;
  background: #fff;
  // padding: 15px 15px 0px 0px;
  // position: relative;
  // overflow: hidden;
  // margin: 10px 0px;
}

.chooseplan_card-dis {
  width: 50%;
  border-radius: 5px;
  border: 1px solid #cdced9;
  background: #fff;
  padding: 8px;
  position: relative;
  height: 108px;
  overflow: hidden;
  margin: 10px 0px;
}

.choose_plan_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 279px;
  height: calc(100vh - 175px);
  overflow: scroll;
}

.date_submit {
  border-bottom: 0.5px solid #eee;
  background: #1a73e8;
  padding: 10px 0px;
  box-shadow: 0px -1px 10px 0px rgba(96, 178, 70, 0.25);
}

.data_selecter {
  width: 100%;
  display: flex;
  justify-content: center;
  height: calc(100vh - 250px);
}

.rq__date__form__wraper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  // margin: auto;
}

.mobile-submir-date {
  display: none;
}

.choose_paymnet_plan {
  color: #333860;
  font-feature-settings: "liga" off;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 120% */
}

.choose_plan_headers {
  color: #333860;
  font-family: Roboto;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: 24px;
}

.welcome_home_header a {
  font-size: 20px;
  color: #516beb;
  text-decoration: underline;
}

.welcome_home_header span {
  font-size: 20px;
  color: #516beb;
  text-decoration: underline;
  cursor: pointer;
}

@media (min-width: 500px) and (max-width: 991px) {
  .choose_plan_card {
    height: calc(100vh - 340px) !important;
  }

  .mobile-submir-date {
    display: block !important;
  }
}

.--pay_for_phone {
  display: none !important;
}

@media (min-width: 200px) and (max-width: 500px) {
  .choose_plan_card {
    height: calc(100vh - 175px) !important;
  }

  .mobile-submir-date {
    display: block !important;
  }

  .discountCard {
    padding: 5px 7px;
  }

  .choose_paymnet_plan {
    color: #333860;
    font-feature-settings: "liga" off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
  }

  .choose_plan_headers {
    font-size: 16px;
  }

  .--pay_for_phone {
    display: block !important;
  }

  .--pay_for_desk {
    display: none;
  }
}

// @media (min-width:1120px)and (max-width:1550px) {
//   .data_selecter {
//     height: calc(100vh - 279px) !important;
//   }
// }

// @media (min-width:1551px) and (max-width:2500px) {

//   .data_selecter {
//     height: calc(100vh - 315px);
//   }
// }

// @media (min-width:2501px) and (max-width:6500px) {

//   .data_selecter {
//     height: calc(100vh - 385px);
//   }
// }

.date-selecter_img {
  padding: 6px 20px;
  width: 35%;
}

.promise_ogback-btn {
  width: 35%;
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f3f8;
  border-radius: 5px;
  flex-direction: column;
  gap: 10px;
  padding: 11px 17px;
  border-style: dashed;
  border-width: 1px;
  border-color: #cdced9;
  border-spacing: 5px;
  text-align: center;
  width: 100%;
}

.error_subheading {
  color: #333860;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.file_upload_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 20px 10px;
  margin-bottom: 15px;

  gap: 50px;
}

.reupload_doc {
  color: #516beb;
  text-align: center;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  /* 136.364% */
  text-decoration-line: underline;
  cursor: pointer;
}

.offline_payment {
  color: #333860;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 5px;
  line-height: normal;
}

.sub_heading-offline-payment {
  color: #757575;
  font-family: Roboto;
  padding-bottom: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  /* 130.769% */
}

.file_upload_icon_name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.file_upload_icon_name svg {
  height: 30px;
  width: 30px;
}

.file_text_wraper--field {
  position: absolute;
  top: 8px;
  font-size: 8px;
  left: 7px;
  font-weight: 900;
}

.mobile_table-card {
  height: 150px !important;
}

@media (min-width: 100px) and (max-width: 991px) {
  .tabviewpayment {
    display: none !important;
  }
}

.welcome_home_header {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}

.header_home_page_payabel {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  text-align: center;
}

@media (min-width: 250px) and (max-width: 900px) {
  .welcome_home_header {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
  }
}

@media (min-width: 250px) and (max-width: 415px) {
  .header_home_page_payabel {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    text-align: center;
  }
}
