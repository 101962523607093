.sidebar-wrapper {
  background-color: #2a2f42;
  height: 100vh;
  width: 176px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  padding: 10px 0px;
}

img {
  max-width: 100%;
}

.sidebar-top {
  /* width: 60px; */
  text-align: center;
}

.sidebar-middle {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  text-align: center;
  color: #516beb;
}



.icon-parent {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: "center";
  padding: 10px;
  transition: 0.3s;
}

.icon-child {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  transition: 0.3s;
  border: 1px solid #ffffff00;
}

.icon-child div {
  margin-left: 12px;
}

.icon-parent:hover .icon-child svg path {
  fill: #fff;
}

.icon-parent:hover .icon-child div {
  color: #fff;
}

.hoverclass .icon-child {
  transition: 0.3s;
  border-radius: 5px;
}

.hoverclass .icon-child svg path {
  transition: 0.3s;
  fill: #ffffff;
}

.hoverclass .icon-child div {
  transition: 0.3s;
  color: #ffffff;
}

.sidebar-middle__menu {
  display: flex;
  width: 100%;
  text-align: left;
  align-items: center;
  border-bottom: 0.5px solid #6a7187;
}

.sidebar-middle__menu-link {
  display: flex;
  width: 100%;
  text-align: left;
  align-items: center;
}

.logo-align {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 40px;
  min-height: 40px;
  overflow: hidden;
  width: 100%;
  padding: 15px;
}

/* .logo-align img {
  margin-left: 12px;
} */

.logo-align div {
  margin-left: 12px;
}

.logo-align img {
  object-fit: contain;
  height: 55px;
}



.sidebar-footer__pw-by {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
/* .nav-bar-empty-div {
  width: 115px;
} */

.footer-wraper__text img {
  filter: brightness(0.4);
}
/* .powered-by {
  font-size: 12px;
} */
/* .nav-header {
  width: 560px;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.nav-header{
  width: 75%;
  -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
}
.header_name_web{
  display: block;
}
.header_name_mobile{
  display: none;
}
@media (min-width: 360px) and (max-width: 415px) {
  .nav-header {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    width: 345px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
  }
  .icon-child svg path {
    fill: #adadad;
  }
  .powered-by {
    font-size: 9px;
  }
  .sidebar-footer__pw-by {
    /* padding-top: 15px; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    gap: 0px;
  }
  .nav-bar-empty-div {
    width: 0px;
  }

  .hoverclass .icon-child svg path {
    transition: 0.3s;
    fill: #516beb !important;
  }

  .hoverclass .icon-child div {
    transition: 0.3s;
    color: #516beb !important;
    font-weight: 700;
    font-size: 10px;
  }

  .header_name_web{
    display: none;
  }
  .header_name_mobile{
    display: block;
  }
}



/* @media (min-width: 320px) and (max-width: 480px) {
.layout-container{
  flex-direction: column-reverse;
  height: 100vh;
}
} */
