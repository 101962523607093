$main-color: #1a73e8;
$secondary-color: #cdced9;
$background-color: #fff;
$text-color: #333860;
// $default-color: ...; // Set your default color value here
.DownloadTextframe {
  cursor: pointer;
  display: flex;
  width: 357px;
  height: 48px;
  padding: 14px 10px;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid $secondary-color;
  background: $background-color;

  .downloadTextBox {
    padding-bottom: 10px;
    height: 34px;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 7px;
    position: relative;

    .last-Download-Date {
      color: #787;
      font-family: "Roboto";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .document-container-filename {
      display: flex;
      align-items: center;
      gap: 7.5px;
    }
    .document-file-Name {
      display: flex;
      flex-direction: column;
      height: 18px;
      color: #333860;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      min-width: 245px;
      max-width: 250px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .document-file-Name-without-last-downoad {
      display: flex;
      flex-direction: column;
      height: 18px;
      position: relative;
      top: 4px;
      color: #333860;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      min-width: 245px;
      max-width: 250px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .document-last-date {
      color: #787;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    .download-text-wrapper {
      color: $text-color;
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 400;
      height: 18px;
      letter-spacing: 0;
      line-height: 18px;
      position: relative;
      white-space: nowrap;
      width: 149px;
      padding-bottom: 35px;
    }
  }

  .DownloadLogoSvg {
    // Styling for the DownloadLogoSvg component
  }
}
.authorize-document-container {
  height: calc(100vh - 145px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding-top: 2%;
  gap: 10px;
}
.documents-for-download-container {
  height: calc(100vh - 145px);
  display: flex;
  justify-content: center;
  padding-top: 2%;
  gap: 10px;
}

.document-form-container {
  display: flex;
  flex-direction: column; // Add quotes around column
  align-items: flex-start;
  gap: 9px;
}
