// In your SCSS file

// Shared styles
$font-family: "Roboto";
$font-size: 12px;
$font-weight: 400;
$line-height: 16px;

.document-button-gohome {
  width: 95px;
  border: 1px solid #1a73e8;
  display: inline-flex;
  background-color: #fff;
  height: 36px;
  padding: 5.5px 10px;

  align-items: center;
  border-radius: 5px;
  // Add any other styles you want to override
}

.document_button_gohome_text {
  cursor: pointer;
  color: #1a73e8;
  font-family: Roboto;
  font-size: 13px;
  padding-left: 1px;

  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.custom-text {
  font-family: $font-family;
  font-size: $font-size;
  font-style: normal;
  font-weight: $font-weight;
  line-height: $line-height;
}
.authorize_wrappper--button {
  display: flex;
  justify-content: center;
  gap: 20px;
}

// Specific styles for individual elements
.chat-text {
  @extend .custom-text; // Inherit common styles
  // Additional styles specific to Chat
}

.download-text {
  @extend .custom-text; // Inherit common styles
  // Additional styles specific to Download
}
@media (min-width: 100px) and (max-width: 500px) {
  .document-download_button {
    display: flex;
    width: 168px;
    align-items: flex-start;
    gap: 8px;
    position: relative;
  }
}
