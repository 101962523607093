@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  margin: 0;
  font-size: 13px;
  box-sizing: border-box;
  padding: 0;
  font-family: "Roboto", sans-serif;
  border: none;
}
h3 {
  font-size: 48px;
}
body {
  margin: 0px;
  background-color: var(--surface);
}
.elip {
  overflow: hidden;
  text-overflow: ellipsis;
}
.tableWrap {
  display: block;
  overflow-x: scroll;
  overflow-y: scroll;
  height: 100vh;
  /* height: 50vh; */
  width: 100%;
  text-align: center;
}
.tableWrap-over {
  /* margin-top: 40px; */
  display: block;
  position: relative;
  overflow-x: scroll;
  overflow-y: scroll;
  /* height: 458px; */
  /* height: 72%; */
  text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}

.btn-boter {
  width: 5px;
  height: 100%;
  /* background-color: rgb(194 16 16 / 0%); */
  /* cursor: pointer !important; */
  position: absolute;
  right: 0px;
  overflow: hidden;
  border: 1px solid transparent;
  top: 1px;
  transition: 0.3s;
}
.btn-boter:hover {
  opacity: 1;
  /* border-right: 1px solid rgb(184, 181, 181); */
  /* background-color: rgb(201, 201, 201); */
}
.UpDown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 2px;
  right: 40px;
}
.RapIcon {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.IconUp {
  margin-bottom: -2px;
}
.IconDown {
  margin-top: -2px;
}
.wraper {
  display: flex;
  width: calc(100% - 60px);
  height: 100vh;
  flex-direction: column;
  /* padding: 25px 25px 0px 25px; */
  width: 100%;
}

.trow {
  background-color: #f3f3f3;
  border: 1px solid #cdced9;
  /* min-width: 0px; */
  /* margin: 12px; */
  /* width: 1411px; */
  /* height: 26px; */
  /* border-bottom: 26px solid #fbfbfb; */
  /* border-left: 1px solid #666666; */
}
.trow-collapse {
  border: 1px solid black;
  /* z-index: 9999; */
}
.thearRow {
  background-color: #dbd7d7;
}

.resp-table {
  width: 100%;
  display: table;
}
.resp-table-caption {
  display: table-caption;
  text-align: center;
  font-size: 20px;
  margin-bottom: 5px;
  position: sticky;
  top: 0;
  /* z-index: 9; */
}

.resp-table-header {
  display: flex;
  flex-direction: column;
  background-color: #f5f6fa;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid #cdced9;
  overflow: hidden;
}

.table-header-cell {
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: justify;
  width: auto;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.resp-table-body {
  display: table-row-group;
}
.resp-table-row {
  display: table-row;
  margin-top: 7px;
  border: 1px solid #cdced9;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  height: 50px;
}
.resp-table-row-over {
  display: table-row;
  /* margin-top: 82px; */
  border-bottom: 0.5px solid #cdced9;
  /* border-radius: 5px; */
  justify-content: center;
  align-items: center;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  height: 50px;
  /* overflow: hidden; */
}
.table-body-cell {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterType {
  width: 100%;
  background-color: #fff;
  height: 20px;
}
.heading-summary {
  margin-bottom: 25px;
}
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.CheckBox {
  width: 17px;
  height: 17px;
  margin-top: 4px;
}
.sort-down {
  fill: #000;
}
.drag-drop {
  margin-right: 5px;
  fill: #fff;
}
.hoverclass .icon-child {
  transition: 0.3s;
  border-radius: 5px;
}

.hoverclass .icon-child svg path {
  transition: 0.3s;
  fill: #516beb;
}
.top-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
}
.single-select {
  width: 375px;
  margin-right: 17px;
}
.RapIcon .active path {
  fill: #333860;
}
.drag-drop svg ellipse {
  fill: #d6d6d6;
  opacity: 0;
}
.table-header-cell-wrapper:hover .drag-drop svg ellipse {
  opacity: 1;
  transition: opacity 1s;
}
.table-header-cell-wrapper {
  height: 100%;
  display: flex;
  position: relative;
}
.table-header-cell-wrapper:hover .btn-boter {
  border-right: 0.3px solid #d6d6d6;
  transition: 0.5ms;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  height: 46px;
  width: 6px;
}
::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #d6d6d6;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #333860;
}

/* widget-style-start */
.card-head {
  display: flex;
  justify-content: space-between;
}
.arrow {
  position: absolute;
  top: 12px;
  right: 6px;
}
.arrow-1 {
  position: absolute;
  top: 12px;
  right: 3px;
}
.arrow-2 {
  position: absolute;
  top: 0px;
  left: 79px;
}
.sort-up {
  margin-bottom: 5px;
}
/* .edit-svg:hover .edit-fill {
  fill: #ffffff;
} */
.edit-btn:hover .edit-fill {
  fill: #ffffff;
}

.nested-view-content:hover .edit-bin {
  fill: #666666;
}
.upload-sucess {
  background-color: #0fb158;
  width: 50px;
  height: 50px;
  padding: 17px 5px 5px 5px;
  border-radius: 100px;
  outline: 10px solid #0fb15825;
}
.div-total {
  border-right: 0.3px solid #e0e0e0;
  padding: 20px;
}
.file-upload-confirmed {
  background-color: #0fb158;
  width: 40px;
  height: 40px;
  padding: 11px 0px 0px 0px;
  border-radius: 100px;
  /* margin-right: 8px; */
  /* outline: 20px solid #0fb15825; */
}
.clr-svg {
  line-height: 20px;
}
.arrow-container {
  display: inline-block;
  width: auto;
}
/* .arrow-container .arrow-clip {
  border: 1px solid transparent;
  color: #fff;
  display: inline-block;
  background-image: linear-gradient(to right, #a4508b 0%, #5f0a87 74%);
  background-attachment: fixed;
} */
.arrow-clip {
  height: 40px;
  display: inline-block;
  width: 25px;
  clip-path: polygon(30% 0, 100% 50%, 30% 100%, 0% 100%, 73% 50%, 0% 0%);
  background-color: blue;
  height: 40px;
  width: 25px;
}
.template-layout {
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
}
.tab-v {
  overflow-x: scroll;
  overflow-y: scroll;
  width: 100%;
}
.functional-card {
  width: 348px;
  height: 36px;
  border: 0.5px solid #cdced9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-left: 15px;
  justify-content: space-between;
  cursor: pointer;
}
.functional-card:hover p {
  color: #333860 !important;
}
.functional-card svg line {
  stroke: #fff;
}
.functional-card:hover svg line {
  stroke: #333860;
}
.lang-btn:hover svg line {
  stroke: #fff;
}
.edit-layout-input-wrap {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.panel {
  margin-bottom: 0px;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  border: 0.5px solid #cdced9 !important;
}
.note-statusbar {
  display: none;
}
